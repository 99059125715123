import React from 'react'
import styled from 'styled-components'
import Accordion from "@material-ui/core/Accordion";

export const GraphDesktop = styled.div`
  display: block;

  .recharts-legend-item-text {
    font-size: 1rem;
  }

  @media (max-width: 600px) {
    display: none;
  }

  .recharts-text, .recharts-cartesian-axis-tick-value {
    fill: ${props => props.theme.theme.text.primary} !important;
  }
`

export const GraphMobile = styled.div`
  display: none;

  .recharts-legend-item-text {
    font-size: 1rem;
  }

  @media (max-width: 600px) {
    display: block;
  }

  .recharts-text, .recharts-cartesian-axis-tick-value {
    fill: ${props => props.theme.theme.text.primary} !important;
  }
`

export const AxisLabel = styled.h4`
  text-align: center;
  margin: 0 0 12px 0;
`